"use client";

import { useEffect, useRef, ReactNode } from "react";

interface ScrollableCenteredContainerProps {
  children: ReactNode;
}

export default function ScrollableCenteredContainer({
  children,
}: ScrollableCenteredContainerProps) {
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    // Center the scroll position
    if (scrollContainerRef.current) {
      const scrollWidth = scrollContainerRef.current.scrollWidth;
      const clientWidth = scrollContainerRef.current.clientWidth;
      scrollContainerRef.current.scrollLeft = (scrollWidth - clientWidth) / 2;
    }
  }, []);

  return (
    <div className="overflow-auto" ref={scrollContainerRef}>
      {children}
    </div>
  );
}
