"use client";

import React, { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { DownloadIcon } from "@radix-ui/react-icons";

export default function DownloadButton({
  text,
  url,
}: {
  text: string;
  url: string;
}) {
  const [showToast, setShowToast] = useState(false);

  const handleDownload = () => {
    setShowToast(true);
  };

  useEffect(() => {
    if (showToast) {
      const timer = setTimeout(() => setShowToast(false), 1000);
      return () => clearTimeout(timer);
    }
  }, [showToast]);

  return (
    <div className="relative">
      <style jsx>{`
        @keyframes toastAnimation {
          0% {
            opacity: 0;
            transform: translate(-50%, 0);
          }
          10% {
            opacity: 1;
            transform: translate(-50%, -100%);
          }
          90% {
            opacity: 1;
            transform: translate(-50%, -100%);
          }
          100% {
            opacity: 0;
            transform: translate(-50%, -200%);
          }
        }
        .toast-animate {
          animation: toastAnimation 1s ease-in-out;
        }
      `}</style>
      <a href={url} download={text}>
        <Button
          onClick={handleDownload}
          variant="ghost"
          className="w-full justify-between bg-gray-100 hover:bg-gray-200 text-black"
        >
          <span className="font-mono">{text}</span>
          <DownloadIcon className="h-4 w-4 min-w-4 min-h-4 ml-4" />
        </Button>
      </a>

      {showToast && (
        <div
          className="absolute left-1/2 px-2 py-1 bg-black text-white text-xs rounded toast-animate"
          style={{ top: "0", transform: "translateX(-50%)" }}
        >
          Started!
        </div>
      )}
    </div>
  );
}
